<template>
  <div style="padding-bottom: 100px;">
    <!-- 顶部 -->
    <div class="td_head_nav" :style="colorState?'background-color:#3BD367;':''">
      <van-icon name="arrow-left" size="22" color="#ffffff" @click="revert"/>
    </div>
    <div class="top">
      <img src="./img/banner.png" alt="">
    </div>
    <!-- 内容 -->
    <div class="content" >
      <!-- 套餐信息 -->
      <div class="infoTC">
        <div class="price">小计：<span>{{comTick.sellPrice ? comTick.sellPrice/100 : 0}}</span></div>
        <h2>套餐信息</h2>
        <h5>{{comTick.name}}</h5>
        <dl>
          <dt>-包含-</dt>
          <dd v-for="(item,index) in comidityInfo" :key="index">● {{item.name}}</dd>
        </dl>
      </div>
      <!-- 出行人信息 -->
      <div class="infoPeople">
        <div class="title">
          <h2>出行人信息</h2><h6>预定成功之后会有短信提示</h6>
        </div>
        <van-form>
          <van-field v-model="infoForm.name" name="pattern" placeholder="请填写出行人姓名" label="出行人1姓名"/>
          <van-field v-model="infoForm.idCard" name="pattern" placeholder="请填写身份证号码" label="身份证号码1"/>
          <van-field v-model="infoForm.phone" name="pattern" placeholder="请填写出行人电话" label="出行人1电话"/>
        </van-form>
        <van-form v-for="(item,index) in orderUserList" v-if="userNum>1">
          <van-field v-model="item.name" name="pattern" placeholder="请填写出行人姓名" :label="'出行人'+(index+2)+'姓名'"/>
          <van-field v-model="item.certificateNumber" name="pattern" placeholder="请填写身份证号码" :label="'身份证号码'+(index+2)"/>
        </van-form>
      </div>
      <!-- 消费时间 -->
      <div class="ConsumptionTime" v-if="comTick.includeHotel">
        <h2>请选择消费时间</h2>
        <h6>请选择酒店入住日期</h6>
        <div class="time">
          <div class="listTime">
            <template v-for="(item,index) in dateArr" >
              <!-- 不可定 -->
              <div class="date dis" v-if="item.dis">
                <p>{{item.date}}</p>
                <span>不可订</span>
              </div>
              <!-- 可订 -->
              <div class="date" v-else :class="chooseDate === index ? 'choose' : ''" @click="choose(item,index)">
                <p>{{item.date}}</p>
                <span>￥{{comTick.sellPrice ? comTick.sellPrice/100 : 0}}</span>
              </div>
            </template>
          </div>
          <!-- 更多日期 -->
          <!--<div class="more" @click="show = true" >更多 日期</div>-->
        </div>
      </div>
      <!-- 预定须知 -->
      <div class="Reserve">
        <h2>预定须知</h2>
        <p class="html">{{sellerInfo.payNotice || '暂无'}}</p>
      </div>
    </div>
    <!-- 底部 -->
    <div class="bottom">
      <p>总费用</p>
      <span>￥{{comTick.sellPrice ? comTick.sellPrice/100 : 0}}</span>
      <van-button type="primary" @click="pay">立即支付</van-button>
    </div>
    <!-- 日期 -->
    <van-calendar v-model:show="show" @confirm="onConfirm" />
  </div>
</template>
<script>
  import {
    Calendar,
    Icon,
    Toast,
    Popup,
    Form ,
    Field ,
    Button
  } from "vant";

  Date.prototype.formatNoS = function() {
    var s = "";
    s += this.getFullYear() + "-"; // 获取年份。
    s += this.getMonth() + 1 + "-"; // 获取月份。
    s += this.getDate(); // 获取日。
    return s; // 返回日期。
  };
  export default {
    components: {
      "van-icon": Icon,
      "van-calendar": Calendar,
      "van-popup": Popup,
      "van-form":Form,
      "van-field":Field,
      "van-button":Button
    },
    data() {
      return {
        apis:{
          tickets: '/commodity/queryComposeCommoditListByCode',//商家商品列表
          scenic:'/seller/find',//获取景点信息
          findCommodity:'/commodity/findCommodity',//获取景点信息
          pay:'/stlsOrder/create',
        },
        colorState: false,
        show:false,//日期选择
        chooseDate:-1,//选择日期active
        infoForm:{//出行人信息表单
          name:'',
          idCard:'',
          phone:''
        },
        dateArr:[],
        sellerInfo:{},
        comTick:{},
        comidityInfo:[],
        userNum:1,
        orderUserList:[],
        ticketDate:this.$route.query.d ? this.$route.query.d.split(':').join('-') : new Date().formatNoS(),
        submitInfo:{
          date:this.$route.query.d.replace(/:/g,'-') ? this.$route.query.d.split(':').join('-') : '',
          groupOrderdetail:'',
          linkmanName:'',
          orderType:'5',
          remark:'',
          source:3,
          telephone:'',
          reservationPersonName: '', // 联系人姓名
          reqOrderDetails:[
            /*{
              certificateNumber:'',
              certificateType:'',
              commodityId:this.$route.query.cTimeId,
              visitorName:'',
            }*/
          ],
          token:this.$global.token,
        },
      };
    },
    mounted() {
      window.addEventListener("scroll", this.scrollFunc, true);
      document.querySelector('body').setAttribute('style', 'background-color:#eee')
      // window.document.body.style.backgroundColor = '#eee';
    },
    beforeDestroy() {
      document.querySelector('body').removeAttribute('style')
    },
    created(){
      this.getSeller();
      this.getTickets();
      this.ticketDate = this.$route.query.d ? this.$route.query.d.split(':').join('-') : new Date().formatNoS();
    },
    methods: {

      //立即支付
      pay(){
        let _t = this;
        let regPhone = /^1[3456789]\d{9}$/;//电话正则
        if(!_t.infoForm.name) return Toast('请输入出行人1姓名');
        if(!_t.infoForm.idCard) return Toast('请输入身份证号码1');
        if(!_t.isIDcard(_t.infoForm.idCard)) return Toast('身份证号码1输入有误');
        if(!_t.infoForm.phone) return Toast('请输入出行人1电话');
        if(!regPhone.test(_t.infoForm.phone)) return Toast('出行人1电话输入有误');
        _t.submitInfo['groupOrderdetail'] = '';
        if(_t.comTick.includeHotel){
          if( _t.chooseDate < 0) return Toast('请选择日期');
          _t.submitInfo['groupOrderdetail'] = _t.dateArr[_t.chooseDate].date;
        }
        // 511181199211133024
        _t.submitInfo['totalDiscountPoints'] = 0;
        _t.submitInfo['isRealName'] = this.$route.query.realName;
        _t.submitInfo['telephone'] = _t.infoForm.phone;
        _t.submitInfo['reservationPersonName'] = _t.infoForm.name;

        let userNamePass = true;
        let userNameNum = 0;
        let userCardPass = true;
        let usercardNum = 0;
        let userCardSure = true;
        let usercardSureNem = 0;
        let orderUserList = JSON.parse(JSON.stringify(_t.orderUserList));
        orderUserList.map((item,index) => {
          if(!item.name){
            userNamePass = false;
            userNameNum = index;
          }
          if(!item.certificateNumber){
            userCardPass = false;
            usercardNum = index;
          }
        });
        if(!userNamePass) return Toast('请输入出行人'+(userNameNum+2)+'姓名');
        if(!userCardPass) return Toast('请输入出行人'+(usercardNum+2)+'身份证号码');

        orderUserList.map((item,index) => {
          if(!_t.isIDcard(item.certificateNumber)){
            userCardSure = false;
            usercardSureNem = index;
          }
        });
        if(!userCardSure) return Toast('身份证号码'+(usercardSureNem+2)+'输入有误');
        orderUserList.push({
          "certificateNumber": _t.infoForm.idCard,
          "credentialsType": 0,
          "name": _t.infoForm.name
        })
        _t.submitInfo.reqOrderDetails = [{
          certificateNumber:_t.infoForm.idCard,
          certificateType:0,
          linkManId:'',
          commodityId:this.$route.query.commodityId,
          visitorName:_t.infoForm.name,
          discountPoints: 0,
          orderUserList:orderUserList,
        }];

        _t.$post({
          url:_t.apis.pay,
          params: _t.submitInfo
        }).then(res => {
          _t.$global.isAddPerson = false;
          sessionStorage.setItem('buyNum',this.buyNum);
          sessionStorage.setItem('telephone',this.submitInfo.telephone);
          _t.$router.push({
            path:'/payWay',
            query:{
              code:res.data,
              price:_t.comTick.sellPrice,
              buyNum:1,
              back:'tc',
            }
          })
        }).catch(err => {
          Toast(err.message);
        });
      },
      // 验证身份证号
      isIDcard(num) {
        let reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
        if(!reg.test(num)){
          return false
        }
        return true
      },
      fun_date(aa){
        var date1 = new Date(this.ticketDate),
          time1=date1.getFullYear()+"-"+(date1.getMonth()+1)+"-"+date1.getDate();//time1表示当前时间
        var date2 = new Date(date1);
        date2.setDate(date1.getDate()+aa);
        var time2 = date2.getFullYear()+"-"+(date2.getMonth()+1)+"-"+date2.getDate();
        this.dateArr.push({
          id:aa,
          dis:false,
          date: time2,
        })
      },
      //  选择日期
      choose(item,index){
        this.chooseDate = index
      },
      // 日期插件
      formatDate(date) {
        return `${date.getMonth() + 1}/${date.getDate()}`;
      },
      onConfirm(date) {
        this.show = false;
        this.date = this.formatDate(date);
      },

      getTickets(){
        let me = this;
        me.$get({
          url:me.apis.tickets,
          params: {
            parentId: 0,
            commodityTypeId: me.$route.query.typesId,
            sellerId: me.$route.query.sellerId,
            pageSize:100,
            pageIndex:1,
          }
        }).then(rsp => {//thirdName=
          rsp.data.map(item => {
            if(item.commodityId == this.$route.query.commodityId){
              this.comTick = item;
            }
          })
          if(this.comTick.includeHotel){
            this.dateArr = [];
            for(let i = 0;i<8;i++){
              this.fun_date(i)
            }
          }
          this.findCommodity()
        });
      },
      findCommodity(){
        let me = this;
        me.$get({
          url: me.apis.findCommodity,
          params:{
            commodityId: me.comTick.commodityId,
            token: me.$global.token
          }
        }).then(rsp => {
          me.comidityInfo = rsp.commodityDetails;
          me.userNum = rsp.userNum ? rsp.userNum : 1;
          for(let i = 0;i<me.userNum-1;i++){
            me.orderUserList.push({
              "certificateNumber": "",
              "credentialsType": 0,
              "name": ""
            })
          }
        })
      },
      getSeller(){
        let me = this;
        me.$get({
          url: me.apis.scenic,
          params:{
            sellerId: me.$route.query.sellerId,
            token: me.$global.token
          }
        }).then(rsp => {
          this.sellerInfo = rsp;
        })
      },
      // 返回
      revert(num) {
        //返回安卓原生页面或者h5页面
        this.$router.go(-1);
      },
      scrollFunc() {
        let top =
          document.getElementById("app").scrollTop ||
          document.documentElement.scrollTop;
        if (top > 30) {
          this.colorState = true;
        } else {
          this.colorState = false;
        }
      }
    }
  };

</script>
<style lang="scss" scoped>
  body{
    background: #EEEEEE;
  }
  .bottom{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 375px;
    height: 50px;
    line-height: 50px;
    background: #FFFFFF;
    box-shadow: 0px -1px 2px 0px rgba(6, 0, 1, 0.05);
    display: flex;
    z-index: 9;
    p{
      font-size: 16px;
      font-weight: bold;
      color: #141212;
      margin-left: 21px;
    }
    span{
      margin-left: 9px;
      display: block;
      color: #F96937;
      font-size: 14px;
    }
    .van-button{
      width: 109px;
      text-align: center;
      line-height: 33px;
      margin-top: 9px;
      margin-left: auto;
      margin-right: 21px;
      height: 33px;
      border: none;
      background: linear-gradient(90deg, #92E460, #3BD367);
      border-radius: 16px;
    }
  }
  .td_head_nav {
    width: 100%;
    padding: 0 20px;
    height: 46px;
    position: fixed;
    top: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 222;
  }
  .cont {
    width: 100%;
    height: 1000px;
    background: red;
  }
  .top{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    height: 225px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .content{
    margin-top: 40px;
    z-index: 99;
    .Reserve{
      width: 323px;
      margin: 0 auto;
      background: #FFFFFF;
      border-radius: 5px;
      padding: 7px 22px 12px 10px;
      h2{
        font-size: 16px;
        margin-bottom: 15px;
      }
      .html{
        font-size: 12px;
        text-align: justify;
      }
    }
    .ConsumptionTime{
      width: 355px;
      height: 154px;
      background: #FFFFFF;
      border-radius: 5px;
      margin: 0 auto 8px;
      overflow: hidden;
      h2{
        font-size: 16px;
        margin-left: 10px;
        margin-top: 7px;
      }
      h6{
        font-size: 14px;
        margin-top: 15px;
        margin-left: 10px;
        font-weight: normal;
      }
      .time{
        width: 335px;
        height: 75px;
        margin: 6px auto 0;
        background: #F7F7F7;
        display: flex;
        .more{
          width: 30px;
          padding-right: 10px;
          height: 37px;
          padding-top: 7px;
          border: 1px solid #B1B1B1;
          border-radius: 3px;
          background: #FFFFFF url('./img/td_right.png') no-repeat right 6px center;
          background-size: 6px 11px;
          margin-top: 15px;
          font-size: 10px;
          margin-left: 10px;
          padding-left: 6px;
        }
        .listTime{
          /*width: 260px;*/
          overflow-x: auto;
          white-space: nowrap;
          .dis{
            background: #F7F7F7!important;
            p{
              color:#B1B1B1!important;
            }
            span{
              color:#B1B1B1!important;
            }
          }
          .date{
            width: 70px;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid #B1B1B1;
            border-radius: 3px;
            display: inline-block;
            margin-top: 15px;
            margin-left: 9px;
            padding-left: 10px;
            p{
              font-size: 10px;
              margin-top: 6px;
            }
            span{
              color: #FF3E6E;
            }
          }
          .choose{
            background: #3FD467 url('./img/t_date_choose.png') no-repeat right bottom;
            background-size: 18px 18px;
            border: 1px solid #3FD467;
            p{
              color:#fff!important;
            }
            span{
              color:#fff!important;
            }
          }
        }
      }
    }
    .infoPeople{
      width: 355px;
      /*height: 189px;*/
      padding-bottom: 20px;
      background: #FFFFFF;
      border-radius: 5px;
      margin: 0 auto 8px;
      overflow: hidden;
      .van-form{
        &:last-child{
          border-bottom: none;
        }
        border-bottom: 5px solid #D6D6D6;
      }
      .van-field{
        width: 335px;
        margin: 0 auto;
        border-bottom: 1PX solid #D6D6D6;
        color: #141212;
        font-size: 14px;
        padding-left: 0;
        &:last-child{
          border-bottom: none;
        }
        ::v-deep  label{
          color: #141212;
          font-size: 14px;
        }
      }
      .title{
        margin-top: 7px;
        margin-bottom: 9px;
        display: flex;
        align-items:flex-end;
        h2{
          font-size: 16px;
          margin-left: 10px;
        }
        h6{
          font-weight: 400;
          font-size: 12px;
          margin-left: 15px;
        }
      }
    }
    .infoTC{
      position: relative;
      width: 355px;
      padding-bottom: 15px;
      background: #fff;
      margin: 0 auto 8px;
      border-radius: 5px;
      overflow: hidden;
      .price{
        position: absolute;
        font-size: 12px;
        top: 12px;
        right: 12px;
        span{
          color: #3FD467;
        }
      }
      h2{
        font-size: 18px;
        margin-left: 9px;
        margin-top: 6px;
      }
      h5{
        font-size: 14px;
        margin-top: 12px;
        padding-left: 9px;
        padding-bottom: 6px;
        border-bottom: 1PX solid #D6D6D6;
      }
      dl{
        margin-left: 9px;
        margin-top: 9px;
        dt{
          font-size: 12px;
          font-style: italic;
          color: #F96937;
        }
        dd{
          font-size: 12px;
          margin-top: 7px;
          font-weight: 500;
          color: #333333;
        }
      }
    }
  }
</style>
